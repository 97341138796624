import * as React from "react"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { useStaticQuery, graphql } from "gatsby"
import { refreshClosedStoreSettings } from "../contexts/actions"

import { StoreSettingsContext } from "../contexts"

import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"

const useStyles = makeStyles(theme => ({
  noBreak: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    msWordBreak: "break-all",
    epubWordBreak: "break-all",
    wordBreak: "break-word",
    fallbacks: [
      {
        wordBreak: "break-all",
      },
    ],
    msHyphens: "auto",
    mozHyphens: "auto",
    epubHyphens: "auto",
    webkitHyphens: "auto",
    hyphens: "auto",
  },

  mb1: {
    marginBottom: "1rem",
  },
  mb2: {
    marginBottom: "2rem",
  },
  indent: {
    textIndent: "1rem",
    marginBottom: "1rem",
  },
}))

const PrivacyPolicy = () => {
  const { dispatchStoreSettings } = React.useContext(StoreSettingsContext)

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query getStoreName {
      allStrapiStoreInfo {
        edges {
          node {
            address
            email
            nameLong
            nameShort
            siteUrl
          }
        }
      }
    }
  `)

  const storeInfo = data.allStrapiStoreInfo.edges[0].node

  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <div style={{ padding: `0rem 24px 1rem 24px`, marginTop: "100px" }}>
        <Typography variant="h4" gutterBottom>
          {`${storeInfo.nameLong} Privacy Policy`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from ${storeInfo.siteUrl} (the “Site”).`}
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
        >{`PERSONAL INFORMATION WE COLLECT`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”`}
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
        >{`WE COLLECT DEVICE INFORMATION USING THE FOLLOWING TECHNOLOGIES`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`– “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`– “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`– “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.`}
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
        >{`HOW DO WE USE YOUR PERSONAL INFORMATION?`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`Communicate with you;`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`Screen our orders for potential risk or fraud; and`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).`}
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
        >{`SHARING YOUR PERSONAL INFORMATION`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Shopify to power our online store–you can read more about how Shopify uses your Personal Information here:  https://www.shopify.com/legal/privacy.  We also use Google Analytics to help us understand how our customers use the Site–you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.`}
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
        >{`BEHAVIOURAL ADVERTISING`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`You can opt out of targeted advertising by:`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`FACEBOOK – https://www.facebook.com/settings/?tab=ads`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`GOOGLE – https://www.google.com/settings/ads/anonymous`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.indent, classes.noBreak) }}
        >
          {`BING – https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.`}
        </Typography>

        <Typography variant="h6" gutterBottom>{`DO NOT TRACK`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.`}
        </Typography>

        <Typography variant="h6" gutterBottom>{`YOUR RIGHTS`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.`}
        </Typography>

        <Typography variant="h6" gutterBottom>{`DATA RETENTION`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.`}
        </Typography>

        <Typography variant="h6" gutterBottom>{`CHANGES`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.`}
        </Typography>

        <Typography variant="h6" gutterBottom>{`CONTACT US`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb1, classes.noBreak) }}
        >
          {`For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at ${storeInfo.email} or by mail using the details provided below:`}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: clsx(classes.mb2, classes.noBreak) }}
        >
          {`${storeInfo.address}`}
        </Typography>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
